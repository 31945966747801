<template>
  <div class="modal-content d-flex align-items-center justify-content-center text-center">

    <BaseHead 
      :title="$t('views.seller.contracts_new.modal.components.text_001')" 
      :subTitle="$t('views.seller.contracts_new.modal.components.text_002')"
    >
      <img src="@/assets/img/icons-contracts/export.svg" alt="export" />
    </BaseHead>

    <div class="email-input mb-3">
      <input 
        v-model="email" 
        type="email" 
        placeholder="Digite seu e-mail"
        class="form-control"
        style="border: none;"
      />
    </div>

    <button 
      class="btn-continue" 
      @click="nextStep" 
      :disabled="!email"
    >
      {{ $t('views.seller.contracts_new.modal.components.btn.text_001') }}
    </button>
  </div>
</template>

<script>
import ContractExportFilters from './ContractExportFilters.vue';
import BaseHead from './baseHead.vue';
import { markRaw } from 'vue'; 

export default {
  components: {
    BaseHead
  },
  beforeMount() {
    this.$store.dispatch('updateStepBack', false);
  },
  computed: {
    email: {
      get() {
        return this.$store.getters.getDataExportContracts.email;
      },
      set(value) {
        this.$store.commit('updateEmail', value);
      }
    }
  },
  methods: {
    nextStep() {
      this.$store.dispatch('updateComponentActive', markRaw(ContractExportFilters));
    }
  }
};
</script>

<style scoped lang="scss">
.modal-content {
  max-width: 100%;
  border: none;
}

.btn-continue:disabled {
  transition: 0.3s !important;
  opacity: 0.30 !important;
  cursor: not-allowed !important;
}

.email-input {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 100% !important;
  margin: 0 auto !important;

  input[type="email"] {
    border: none !important;
    border-bottom: 2px solid #E5E7E7 !important;
    border-radius: 0 !important;
    color: var(--dark-500) !important;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-size: 28px !important;
    font-weight: 800 !important;
    text-align: center !important;
    letter-spacing: -1.12px !important;
  }

  input[type="email"]:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.form-control::placeholder {
  color: var(--dark-500) !important;
  font-family: "Plus Jakarta Sans" !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: normal !important;
  letter-spacing: -1.12px !important;
  opacity: 0.30;
}
</style>
